table {
    //border-spacing: 10px;
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
            min-width: 30px;
        }

        @media screen and (min-width: 2000px) {
            p {
                max-width: 100%;
            }
        }
    }
}

table tr {
    border-bottom: 1px solid #DEDEDE;
    position: static !important;
}

table tr:last-child {
    border-bottom: none;
}

table th {
    font-weight: bold;
    padding: 10px 0 !important;
    position: static !important;
    white-space: nowrap;
}

table tr td {
    // padding: 10px !important;
}

.column-container {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 6px;
}

.sort-container {
    display: flex;
    flex-direction: column;
}

.sorting-icon {
    top: 5px !important;
}

.sorting-icon:first-child {
    margin-bottom: -2px;
}

.sorting-icon:nth-child(2) {
    margin-top: -2px;
}

.table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    button {
        margin-right: 3px;
        padding: 5px 10px;
        background-color: #fff;
        color: #333;
        border: 1px solid #E8E8E8;
        border-radius: 4px;
        cursor: pointer;
    }

    .active {
        background-color: #1EAAC4;
        border-color: #1EAAC4;
        color: #fff;
    }

}